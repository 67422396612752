@font-face {
    font-family: "GT America Trial Cm Bd";
    font-style: normal;
    font-weight: normal;
    src: local("GT America Trial Cm Bd"),
    url("../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
    font-family: "GT America Trial Cn Lt";
    font-style: normal;
    font-weight: normal;
    src: local("GT America Trial Cn Lt"),
    url("../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

.search-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop-up-search-bar{
    width: 95%;
    height: 95%;
    overflow: auto;
    border-radius: 5px;
    background-color: #fff;
    border: 4px solid #fff;
    animation: fadeIn 0.5s ease;
    z-index: 100;
    font-size: 18px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.search-box.hide{
    display: none;
}

.pop-up-search-bar-header{
    display: flex;
    width: 94%;
    padding: 15px 20px;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    z-index: 100;
}

.pop-up-search-bar-title h3{
    color: #08313a;
    font-family: "GT America Trial Cm Bd";
}

.pop-up-search-bar-close-icon{
    cursor: pointer;
}

.pop-up-search-bar-center-tag{
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pop-up-search-bar-contents{
    width: 85%;
    height: 30%;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 5px;
    padding: 10px 50px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: "GT America Trial Cn Lt";
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:492px) {
    .pop-up-search-bar-contents{
        margin-top: 100px;
    }
}

@media (max-width:450px) {
    .pop-up-search-bar{
        width: 95%;
        height: 85%;
    }
}

