/* Search Bar */
.gsc-results-wrapper-overlay{
    height: 90vh !important;
  }
  
  .gsc-control-cse{
    background-color: transparent !important;
    border: transparent !important;
    width: 350px !important;
    padding: 0 !important;
    margin-top: 0 !important;
    position: relative !important;
    border-radius: 5px !important;
  }
  
  .gsc-input-box{
    border-radius: 15px !important;
  }
  
  .gsst_b{
    display: none !important;
  }
  .gsc-search-button-v2{
    padding: 4px 5px !important;
    position: absolute !important;
    top: 10% !important;
    right: 6% !important; 
    border: none !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
  }
  
  .gsc-search-button-v2 > svg{
    width: 20px !important;
    height: 20px !important;
  }
  
  @media (max-width:955px) {
    .gsc-search-button-v2{
      top: 13% !important;
      right: 6% !important; 
    }
  }
  
  @media (max-width:540px) {
    .gsc-control-cse{
      width: 250px !important;
    }
  }

  @media (max-width:380px) {
    .gsc-control-cse{
      width: 200px !important;
    }

    .gsc-completion-container{
      display: none !important;
    }
  }
  
  /* Search Bar - End */