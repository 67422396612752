.contact-us-video-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
  font-family: "GT-America-2", sans-serif;
  color: #ffffff;
}

.contact-us-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

@media (max-width: 768px) {
  .contact-us-video-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .contact-us-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .contact-us-video-text-box {
    width: 90%;
    margin-left: 0px;
    top: 75vh;
  }

  .contact-us-topic-home {
    font-size: 70px;
    margin-left: 0px;
  }
}


.contact-us-content {
  padding-left: 6%;
  padding-right: 6%;
  margin-bottom: 60px;
}

.contact-us-content h1 {
  color: #313893;
  padding-bottom: 10px;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 65px;
  line-height: 70px;
}

.contact-us-content h2 {
  color: #313893;
  font-family: "GT America Trial Cm Th", sans-serif;
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 10px;
}

.contact-us-content h3 {
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 40px;
  line-height: 70px;
}

.contact-us-content p {
  font-family: "GT America Trial Cn Lt", sans-serif;
  font-size: 20px;
  line-height: 25px;
}

.contact-us-two-column {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .contact-us-column-1 {
    width: 50%;
  }
  
  .contact-us-column-2 {
    width: 50%;
  }
  
  @media screen and (max-width: 768px) {
    .contact-us-two-column {
      flex-direction: column;
    }
  
    .contact-us-column-1,
    .contact-us-column-2 {
      width: 100%;
    }
  }
  