.form-container {
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-group {
  flex: 1;
  min-width: 200px;
}

.contact-form {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.inputStyle,
.textareaStyle {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.textareaStyle {
  resize: vertical;
}

.form-footer {
  text-align: start;
  margin-top: 10px;
}

.submitButton {
  background-color: #313893;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #1c5258;
  color: white;
}

.success-message,
.error-message {
  font-weight: bold;
  font-size: 16px;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}
