.not-found-page-company-logo {
  margin-top: 30px;
  height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.not-found-testimonial-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.not-found-testimonial-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 10px;
  gap: 50px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.not-found-testimonial-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-testimonial-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.not-found-testimonial-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found-button-container {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.not-found-button {
  color: #fff;
  background-color: #313893;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 150px;
  text-align: center;
  font-family: "GT America Trial Cn Lt", sans-serif;
  font-size: 20px;
}

.not-found-button:hover {
  background-color: #1c5258;
  color: #fff;
}

@media (max-width: 1200px) {
  .not-found-testimonial-content {
    padding: 20px;
    gap: 40px;
  }
}

@media (max-width: 600px) {
  .not-found-testimonial-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .not-found-testimonial-image {
    margin-bottom: 20px;
  }

  .not-found-testimonial-text {
    margin-bottom: 40px;
  }
}

@media (max-width: 400px) {
  .not-found-testimonial-content {
    padding: 15px;
    gap: 20px;
  }

  .not-found-testimonial-text {
    margin-bottom: 20px;
  }
}
