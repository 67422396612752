@media (max-width: 870px) {
  .aa-pc {
    display: none;
  }
}

@media (min-width: 870px) {
  .aa-mobile {
    display: none;
  }
}

.gender-button-n {
  margin-top: 10px;
  width: 60px;
  height: 60px;
}

.career-chart-9-srilanka-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.career-chart-9-srilanka-paragraph-column-1 {
  width: 30%;
  border-bottom: 1.4px solid #313893;
}

.career-chart-9-srilanka-paragraph-column-2 {
  width: 60%;
  border-left: 1.4px solid #313893;
  border-right: 1.4px solid #313893;
  border-bottom: 1.4px solid #313893;
}

.career-chart-9-srilanka-paragraph-column-3 {
  width: 10%;
  border-bottom: 1.4px solid #313893;
}

.career-chart-9-srilanka-paragraph-column-4 {
  width: 30%;
}

.career-chart-9-srilanka-paragraph-column-5 {
  width: 60%;
  border-left: 1.4px solid #313893;
  border-right: 1.4px solid #313893;
}

.career-chart-9-srilanka-paragraph-column-6 {
  width: 10%;
}

@media screen and (max-width: 870px) {
  .career-chart-9-srilanka-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .career-chart-9-srilanka-paragraph-column-1 {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
  }

  .career-chart-9-srilanka-paragraph-column-2 {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-right: none;
    border-left: 1.4px solid #313893;
  }

  .career-chart-9-srilanka-paragraph-column-3 {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
    border-bottom: none;
  }

  .career-chart-9-srilanka-paragraph-column-4 {
    width: 100%;
    text-align: center;
  }

  .career-chart-9-srilanka-paragraph-column-5 {
    width: 100%;
    text-align: center;
  }

  .career-chart-9-srilanka-paragraph-column-6 {
    width: 100%;
    text-align: center;
  }
}

.button-image {
  height: 100px;
  width: 50px;
  display: inline-block; /* Add this line */
  background-size: contain;
  background-repeat: no-repeat;
}


/* srilanka-male/female.jsx */
.sri-lanka-box-1-c {
  /* background-color: #b80101; */
  background-color: #000055;
  width: 100%;
  padding: 6px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.sri-lanka-box-2-c {
  /* background-color: #e05e00; */
  background-color: #000055;
  width: 100%;
  padding: 6px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.sri-lanka-box-3-c {
  /* background-color: #00438f; */
  background-color: #000055;
  width: 100%;
  padding: 6px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.gender-topic {
  color: #313893;
  font-size: 20px;
  font-weight: 900;
  font-family: "GT America Trial Md";
}

.career-chart-9-srilanka-paragraph-column-1 p{
  margin-bottom: 0;
  margin-top: 15px;
}

.career-chart-9-srilanka-paragraph-column-1 img{
  margin-top: 0;
}