.footer {
  background-color: #313893;
  color: white;
  overflow-x: hidden;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 10px;
  margin-right: 0;
  margin-left: 0;
}

.bottom-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-family: "GT America Standard Medium", sans-serif;
  font-size: 14px;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.footer-para {
  font-size: 0.7rem;
}

.footer-para a {
  color: white;
  text-decoration: none;
  margin-right: 30px;
  font-family: "GT America Standard Medium", sans-serif;
  font-size: 12px;
}

.social-icon {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.bottom-footer a {
  text-decoration: none;
}

.social-icon:hover {
  color: #f8f9fa;
}

.right-flex {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.icons_circle-footer {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.icons_circle-footer a {
  text-decoration: none;
  color: #ffff;
  transition: 0.2s ease all;
  padding-bottom: 3px;
}

.icons_circle-footer:hover {
  color: #000;
  background-color: #f8f9fa;
  cursor: pointer;
}

.icons_circle-footer:hover a {
  color: #000000;
  scale: 1.5;
  transform: translateY(-0.5px);
}

/* .footer-firm-logo {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -80px;
  margin-bottom: 10px;
}

.footer-firm-logo a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.footer-img {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: -80px;
  margin-bottom: 0px;
}

.footer-firm-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bestweb {
  margin-left: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bestweb img {
  width: 170px;
  height: auto;
  object-fit: cover;
}

.topweb {
  margin-left: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.topweb img {
  width: 80px;
  height: auto;
  object-fit: cover;
  border-radius: 5%;
}

.hirdaramani img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.empty-column {
  flex: 1;
}

.empty-column {
  flex: 1;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .footer-img {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .footer-firm-logo {
    margin-bottom: 20px;
    flex-direction: column;
  }
  .empty-column {
    display: none; /* Hides the empty column on mobile */
  }

  .bestweb {
    margin-left: 0%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .topweb {
    margin-left: 0%;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .empty-column {
    display: none; /* Hides the empty column on mobile */
  }
}

.mo-text {
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  font-family: "GT America Standard Medium", sans-serif;
  font-size: 14px;
}

.mo-text a {
  text-decoration: none;
}

@media (max-width: 600px) {
  .footer-pc {
    display: none;
  }
}

@media (min-width: 601px) {
  .footer-mobile {
    display: none;
  }
}

/* Media query for small devices (e.g., smartphones) */
@media (max-width: 768px) {
  .flex {
    flex-direction: column;
    align-items: center;
    margin-right: -10px;
    margin-left: -10px;
  }

  .right {
    text-align: left;
    margin-top: 20px;
  }

  .left {
    text-align: left;
  }

  .footer-para {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .social-icon {
    margin: 0 5px;
  }
}

@media (max-width: 600px) {
  .left {
    text-align: center;
  }
}

/* Media query for extra small devices (e.g., smartphones in portrait mode) */
@media (max-width: 576px) {
  .footer-para a {
    margin-right: 10px;
  }
}
