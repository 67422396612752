@media (max-width: 870px) {
  .aa-pc {
    display: none;
  }
}

@media (min-width: 870px) {
  .aa-mobile {
    display: none;
  }
}

.empower-chart-4-srilanka-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.empower-chart-4-srilanka-paragraph-column-1 {
  width: 30%;
  border-bottom: 1.4px solid #313893;
}

.empower-chart-4-srilanka-paragraph-column-2 {
  width: 40%;
  border-left: 1.4px solid #313893;
  border-right: 1.4px solid #313893;
  border-bottom: 1.4px solid #313893;
}

.empower-chart-4-srilanka-paragraph-column-3 {
  width: 30%;
  border-bottom: 1.4px solid #313893;
}

.empower-chart-4-srilanka-paragraph-column-4 {
  width: 30%;
}

.empower-chart-4-srilanka-paragraph-column-5 {
  width: 40%;
  border-left: 1.4px solid #313893;
  border-right: 1.4px solid #313893;
}

.empower-chart-4-srilanka-paragraph-column-6 {
  width: 30%;
}

@media screen and (max-width: 870px) {
  .empower-chart-4-srilanka-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .empower-chart-4-srilanka-paragraph-column-1 {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
  }

  .empower-chart-4-srilanka-paragraph-column-2 {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-right: none;
    border-left: 1.4px solid #313893;
  }

  .empower-chart-4-srilanka-paragraph-column-3 {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
    border-bottom: none;
  }

  .empower-chart-4-srilanka-paragraph-column-4 {
    width: 100%;
    text-align: center;
  }

  .empower-chart-4-srilanka-paragraph-column-5 {
    width: 100%;
    text-align: center;
  }

  .empower-chart-4-srilanka-paragraph-column-6 {
    width: 100%;
    text-align: center;
  }
}

.button-image2 {
  height: 100px;
  width: 50px;
  display: inline-block; /* Add this line */
  background-size: contain;
  background-repeat: no-repeat;
}


.selected {
  background-size: contain !important;
}

.sri-lanka-text-box-1 {
  background-color: transparent;
  width: 100%;
  /* color: #e05e00; */
  color: #000055;
  font-size: 26px;
  font-weight: 700;
}

.sri-lanka-text-box-3 {
  background-color: transparent;
  width: 100%;
  /* color:  rgb(17, 107, 79); */
  color: #000055;
  font-size: 26px;
  font-weight: 700;
}

/* srilanka-male/female.jsx */
.sri-lanka-box1 {
  /* background-color: #f0a774; */
  background-color: #000055;
  width: 100%;
  padding: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.sri-lanka-box3 {
  /* background-color:  rgb(17, 107, 79); */
  background-color: #000055;
  width: 100%;
  padding: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.gender-topic {
  color: #313893;
  font-size: 20px;
  font-weight: 900;
  font-family: "GT America Trial Md";
}
