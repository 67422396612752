.employeeByCountryChart-container {
    position: relative;
  }
  
  .worldmapbg-image {
    top: 0;
    left: 0;
    z-index: 0;
  }
  
  .srilanka-employeeImage {
    position: absolute;
    cursor: pointer;
    top: 232px; /* Adjust top position as needed */
    left: 284px; /* Adjust left position as needed */
    z-index: 1; /* Make sure it's above the background image */
  }
  
  .ethiopia-employeeImage {
    position: absolute;
    cursor: pointer;
    top: 240px;
    left: 234px;
    z-index: 1;
  }
  
  .bangladesh-employeeImage {
    position: absolute;
    cursor: pointer;
    top: 209px;
    left: 298px;
    z-index: 1;
  }

  .vietnam-employeeImage {
    position: absolute;
    cursor: pointer;
    top: 209px;
    left: 321px;
    z-index: 1;
  }

  .selectedCountry-data {
    position: absolute;
    top: 159px;
    left: 297px;
    z-index: 1;
  }

  .employeeByCountryTopic {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    color: rgb(49, 56, 147);
    font-family: "GT America Trial Md";
    padding-top: 50px;
}

  .employee-dataNumber {
    position: absolute;
    z-index: 1;
    color: #000055;
    font-family: "GT America";
    font-weight: 800;
    font-size: 30px;
  }
  
  /* Adjust the top and left positions for each employee data */
  .srilanka-employeeImage + .employee-dataNumber {
    top: 290px;
    left: 240px;
  }
  
  .ethiopia-employeeImage + .employee-dataNumber {
    top: 290px;
    left: 205px;
  }
  
  .bangladesh-employeeImage + .employee-dataNumber {
    top: 290px;
    left: 253px;
  }
  
  .vietnam-employeeImage + .employee-dataNumber {
    top: 290px;
    left: 267px;
  }
  