.cookies-policy-company-logo {
  margin-top: 30px;
  height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

@media (max-width: 768px) {
  .screen-pc {
    display: none;
  }
}

@media (min-width: 769px) {
  .screen-mobile {
    display: none;
  }
}

.cookies-policy-logo-2 {
  margin-top: 30px;
  height: 80px;
  position: absolute;
  right: 30px;
  z-index: 2;
}

.cookies-policy-content-1 {
  padding-left: 6%;
  padding-right: 6%;
}
.cookies-policy-content-1 h2 {
  color: #313893;
  font-family: "GT America Trial Cm Th", sans-serif;
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 10px;
}

.cookies-policy-content-1 h1 {
  color: #313893;
  padding-bottom: 10px;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 65px;
  line-height: 70px;
}

.cookies-policy-content-1 p {
  font-family: "GT America Trial Cn Lt", sans-serif;
  font-size: 20px;
  line-height: 25px;
}

.cookies-policy-content {
  padding-left: 6%;
  padding-right: 6%;
}
.cookies-policy-content h2 {
  color: #313893;
  font-family: "GT America Trial Cm Th", sans-serif;
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 10px;
}

.cookies-policy-content h1 {
  padding-top: 20px;
  padding-bottom: 10px;
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 65px;
  line-height: 70px;
}

.cookies-policy-content p {
  font-family: "GT America Trial Cn Lt", sans-serif;
  font-size: 20px;
  line-height: 25px;
}

.cookies-policy-content ul {
  font-family: "GT America Trial Cn Lt", sans-serif;
  list-style-type: disc;
  font-size: 19px;
  line-height: 25px;
  margin-left: 20px;
}

.cookies-policy-content li {
  margin-bottom: 10px;
}