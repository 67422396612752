.trust-section-card-container-1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  flex: 1 1 auto;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.card.trust-chart-01-card{
  min-width: 150px;
  margin-bottom: 30px;
}

.trust-chart-img-2 {
  max-width: 100%;
}

.trust-Sri_Lanka_2023 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #00438f; */
  color: #000055;
}

.trust-Sri_Lanka_2022 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: rgb(17, 107, 79); */
  color: #000055;
}

.trust-Sri_Lanka_Target {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #b80101; */
  color: #000055;
}

.sri_lanka-ethiopia-number-loader {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #000055;
  z-index: 20;
}

.trust-para-5-female {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #ec7372;
}

.trust-middle-topic {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
}

.middle-topic-3 {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
}

.trust-chart-01-chart-box {
  width: 100%;
}
