.company-logo-2 {
  margin-top: 30px;
  height: 80px;
  position: absolute;
  right: 30px;
  z-index: 2;
}

.search-icon-home {
  margin-top: 130px;
  position: absolute;
  right: 43px;
  z-index: 2;
  cursor: pointer;
}

.search-icon-home-svg:hover{
  scale: 1.1;
  transition: 0.3s ease all;
}