.carousal {
  background-color: rgb(30, 109, 84);
  vertical-align: middle;
}

.topic {
  color: rgba(251, 255, 251, 0.89);
  font-size: 2.6rem;
  padding-top: 50px;
  padding-bottom: 0px;
  font-weight: bolder;
}

.topic-2 {
  color: rgba(237, 240, 237, 0.89);
  font-size: 1.2rem;
  padding-top: 0px;
  padding-bottom: 20px;
  font-weight: bolder;
}

.carousal-container {
  height: 750px;
}

.inside-carousal .carousal-slider {
  width: 70%;
  height: 350px;
  margin: 0 auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .topic {
    color: rgba(251, 255, 251, 0.89);
    /* color: rgba(254, 255, 254, 0.89); */
    font-size: 2.5rem;
    padding-top: 100px;
    padding-bottom: 10px;
    font-weight: bolder;
  }

  .carousal-container {
    height: 650px;
  }

  .carousal {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .topic {
    color: rgba(251, 255, 251, 0.89);
    /* color: rgba(254, 255, 254, 0.89); */
    font-size: 2.5rem;
    padding-top: 100px;
    padding-bottom: 10px;
    font-weight: bolder;
  }

  .carousal-container {
    height: 600px;
  }

  .inside-carousal .carousal-slider {
    width: 90%;
    height: 350px;
    margin: 0 auto;
  }
}

/* Hide Swiper slider on screens larger than 600px */
@media only screen and (min-width: 601px) {
  .slider {
    display: none;
  }
}

/* Add this CSS to handle video styling */
/* .carousal-video {
  width: 200%;
  height: 200%;
  position: relative;
  top: 150%;
  transform: translateY(-50%);
} */

.arrow-v {
  position: absolute;
  top: 50%;
  z-index: 1;
  font-size: 25px;
  line-height: 25px;
  cursor: pointer;
  color: #a3e1cc;
  transition: color 0.1s ease;
}

.arrow-v:hover {
  color: #808080;
}

.left-arrow-v {
  left: -5%;
}

.right-arrow-v {
  right: -5%;
}

.video-controls {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  gap: 5px;
  display: none; /* remove the buttons */
}

.video-controls button {
  background-color: rgba(0, 0, 0, 0.28);
  border: none;
  color: white;
  padding: 5px;
  width: 30px;
  cursor: pointer;
  border-radius: 5px;
}

.video-controls button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .left-arrow-v {
    left: -10%;
  }

  .right-arrow-v {
    right: -10%;
  }
}

@media (max-width: 600px) {
  .left-arrow-v {
    left: 2%;
  }

  .right-arrow-v {
    right: 2%;
  }

  .video-controls {
    right: 4px;
    gap: 10px;
    bottom: 9px;
  }

  .video-controls button {
    width: 30px;
  }
}
