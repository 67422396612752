@font-face {
    font-family: 'GT America Trial Lt';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Lt'), url('../fonts/GT-America-Standard-Light-Trial.woff') format('woff');
}

.sitemap-page-contents{
    padding: 100px 0;
    width: 90%;
}

.sitemap-company-logo {
    margin-top: 30px;
    height: 80px;
    z-index: 2;
}

.sitemap-page-topic-section h1{
    color: #313893;
    font-family: "GT America Trial Cm Bd", sans-serif;
    font-size: 70px;
    line-height: 70px;
    margin-bottom: 50px;
    user-select: none;
}

.sitemap-page-coloumn h2{
    color: #313893;
    font-family: "GT America Trial Cm Bd", sans-serif;
    font-size: 45px;
    line-height: 45px;
    user-select: none;
}

.sitemap-page-content-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sitemap-page-coloumn-sub-headings{
    display: flex;
    flex-direction: column;
    user-select: none;
}

.sitemap-page-coloumn-sub-headings a{
    font-family: 'GT America Trial Lt';
    text-decoration: none;
    color: #313893;
    font-size: 25px;
}



/* Media Query */

@media (max-width:1170px) {
    .sitemap-page-content-section{
        flex-direction: column;
        gap: 40px;
    }
}